
    // Do not modify this file by hand!
    // Re-generate this file by running lit-localize

    
    

    /* eslint-disable no-irregular-whitespace */
    /* eslint-disable @typescript-eslint/no-explicit-any */

    export const templates = {
      's1b9047d53d9f9d22': `2) Pressione Compartilhar na barra de navegação`,
's37a9e8aec5713460': `Mais`,
's5c6aad7a7e4a1437': `3) Pressione Adicionar à tela inicial`,
's6196153c4b0c1ea0': `Instalar`,
's8d0173763d91830c': `Este site tem funcionalidade de aplicativo. Adicione-o à sua tela inicial para uma ampla experiência e fácil acesso.`,
'sa5ef80b4bb9b39f8': `Menos`,
'sc79fd7641eb9a975': `Esconder instruções`,
'scdaf4bbff76674c8': `Adicionar à tela inicial`,
'sdca23702207fba73': `Este site tem funcionalidade de aplicativo. Instale-o em seu dispositivo para obter uma ampla experiência e fácil acesso.`,
'sdfe23506a3b0cdc8': `1) Abra no navegador Safari`,
    };
  